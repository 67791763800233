@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  body {
    @apply antialiased text-gray-900 dark:text-white bg-white dark:bg-gray-900 transition-colors duration-300;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-bold tracking-tight;
  }
}

@layer components {
  .glass-effect {
    @apply bg-white/90 dark:bg-gray-800/90 backdrop-blur-lg shadow-lg;
  }

  .gradient-text {
    @apply bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500;
  }

  .hover-effect {
    @apply transition-all duration-300 ease-in-out hover:scale-105;
  }

  .card {
    @apply bg-white dark:bg-gray-800 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300;
  }

  .button-primary {
    @apply inline-flex items-center px-6 py-3 bg-indigo-600 text-white rounded-full 
           hover:bg-indigo-700 transition-all duration-300 transform hover:scale-105 
           focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2;
  }

  .button-secondary {
    @apply inline-flex items-center px-6 py-3 border-2 border-gray-300 dark:border-gray-600 
           text-gray-700 dark:text-gray-300 rounded-full hover:border-indigo-600 
           hover:text-indigo-600 dark:hover:border-indigo-400 dark:hover:text-indigo-400 
           transition-all duration-300 transform hover:scale-105;
  }
}

.bg-grid-pattern {
  background-image: linear-gradient(to right, rgb(var(--color-gray-200) / 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgb(var(--color-gray-200) / 0.1) 1px, transparent 1px);
  background-size: 4rem 4rem;
}

/* Scroll Behavior */
html {
  scroll-behavior: smooth;
  @apply scroll-pt-20;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  @apply bg-gray-100 dark:bg-gray-800;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-300 dark:bg-gray-700 rounded-full transition-colors;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-400 dark:bg-gray-600;
}

/* Animations */
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.animate-float {
  animation: float 6s ease-in-out infinite;
}

/* Focus Styles */
:focus-visible {
  @apply outline-none ring-2 ring-indigo-500 ring-offset-2 ring-offset-white dark:ring-offset-gray-900;
}

/* Selection */
::selection {
  @apply bg-indigo-500/20 text-indigo-900 dark:text-indigo-100;
}
